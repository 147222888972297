import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from "../images/logo/pntOs_Logo_Gradient_Light_Horizontal.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `var(--pntos-dark-blue)`,
      padding:"1rem"
    }}
  >
    <div>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img style={{maxWidth: "250px"}} src={Logo} alt="pntOS logo" />
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `pntOS.com`,
}

export default Header
